import React from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";
import fonts from "../../styles/fonts";
import BackgroundImage from "gatsby-background-image";
import Line from "../core/Line";
import ShortOverlayBottom from "../core/ShortOverlayBottom";

const Background = styled.div`
    height: 440px;
    background: #020913;
    z-index: -11;
    @media(max-width: 768px) {
        height: 400px;
    }
`

const BackgroundStyled = styled.div`
    position: absolute;
    height: 460px;
    margin-top: -97px;
    text-align: center;
    background: #020913;
    @media(max-width: 768px) {
        margin-top: -138px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  height: 100%;
  background-size: cover;
  max-height: 500px;
`;

const TitleContainer = styled.div`
    max-height: 420px;
    padding-top: 51px;
    text-align: center;
    @media(min-width: 768px) {
        padding-top: 95px;
    }
`;

const OutlinedLine = styled.div`
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 46px;
    letter-spacing: 3px;
    color: transparent;
    line-height: 46px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #FFFFFF;
    @media(min-width: 768px) {
        padding-left: 0;
        font-size: 78px;
        letter-spacing: 5px;
        text-align: center;
        line-height: 87px;
    }
    @media(max-width: 400px) {
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 30px;
        padding-left: 15px;
    }
`;

const Title = styled.h1`
    text-transform: uppercase;
    color: white;
    letter-spacing: 2.2px;
    margin-bottom: 0;
    ${fonts.swissBlackExtended};
    font-size: 46px;
    line-height: 46px;
    cursor: default;

    @media (min-width: 768px) {
        font-size: 78px;
        line-height: 87px;
        letter-spacing: 5px;
    }  

    @media(max-width: 400px) {
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 30px;
        padding-left: 15px;
    }
`;

const Hero = ({ hero }) => {

  return (
    <Background>
      <BackgroundStyled>
        <BackgroundImageStyled fluid={hero.childImageSharp.fluid} critical={true} durationFadeIn={200} alt={"Hero"}/>
        <ShortOverlayBottom/>
      </BackgroundStyled>
      <TitleContainer>
        <Fade duration={750} delay={500}>
          <Title>Shares for </Title>
          <OutlinedLine>Shipmates</OutlinedLine>
        </Fade>
        <Fade bottom duration={750} delay={500}>
          <Line noVertical/>
        </Fade>
      </TitleContainer>
    </Background>
  )
}

export default Hero;
