import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import Quote from "../../resources/images/investment/quotation.svg"

const StyledContainer = styled.div`
  position: relative;
  margin-top: 140px;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -290px;
  display: none;
  @media (min-width: 650px) {
    display: block;
  }
`

const BackgroundMobile = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: -430px;
  @media (min-width: 650px) {
    display: none;
  }
`

const RowStyled = styled(Row)`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 25px;
  margin-bottom: 100px;
`

const ColStyled = styled(Col)`
  padding: 0;
  display: ${props => (props.flex ? "flex" : "block")};
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`

const ImageContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: 648px;
  }
`

const Content = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.grey};
  font-size: 15px;
  line-height: 21px;
  max-width: 420px;
  text-align: center;
  img {
    margin-bottom: 36px;
  }
  padding: 50px 25px 0px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    margin-right: 20px;
  }
`

const WhiteText = styled.div`
  ${fonts.swissBlackExtended};
  color: ${colors.white};
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 30px;
`

const Mission = props => {
  const { background, image, backgroundMobile } = props
  return (
    <StyledContainer>
      <Background>
        <Img fluid={background.childImageSharp.fluid} alt="Wave background" />
      </Background>
      <BackgroundMobile>
        <Img fluid={backgroundMobile.childImageSharp.fluid} alt="Wave background" />
      </BackgroundMobile>
      <RowStyled>
        <ColStyled md={6} mdOrder={"last"}>
          <ImageContainer>
            <Img
              fluid={image.childImageSharp.fluid}
              alt="Our mission, lost at sea car"
            />
          </ImageContainer>
        </ColStyled>
        <ColStyled md={6} flex alignSelf={"center"}>
          <Content>
            <img src={Quote} alt="Quotation mark" />
            <WhiteText>
              Our mission is as simple as it is inspiring: become one of the
              worlds leading beer brands
            </WhiteText>
            <div>Mike Holmes - Co-Founder</div>
          </Content>
        </ColStyled>
      </RowStyled>
    </StyledContainer>
  )
}

export default Mission
