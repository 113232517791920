import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const StyledButton = styled.button`
  cursor: pointer;
  text-decoration: none;
  background: ${props => (props.isBorder ? colors.navy : "transparent")};
  text-transform: uppercase;
  font-size: 12px;
  ${fonts.gilroyBold};
  border: 1px solid ${colors.mint};
  height: 44px;
  width: 200px;
  text-align: center;
  color: ${props => props.isBorder ? colors.navy : "#D1D1D1"};
  margin-left: 12px;
  letter-spacing: 2.77px;
  transition: background 0.3s, color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s;
  position: relative;
  &:hover {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background-color: ${props => (props.isBorder ? colors.mint : colors.navy)};
    color: ${colors.navy};
  }
  &:active,
  &:focus {
    outline: none;
  }
`

const BorderStyled = styled.div`
  border-bottom: 5px solid ${colors.mint};
  border-right: 5px solid ${colors.mint};
  width: 192px;
  height: 39px;
  right: -5px;
  bottom: -5px;
  position: absolute;
  display: ${props => props.isVisible ? "block" : "none"};
`

const OnClickButton = ({children, onClick, type}) => {
  const [isBorder, setIsBorder] = useState(false)

  const toggleHover = () => {
    setIsBorder(!isBorder)
  }
  return (
    <StyledButton
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleHover()}
      onClick={onClick}
      type={type}
      isBorder={isBorder}
    >
      {children}
      <BorderStyled isVisible={!isBorder} />
    </StyledButton>
  )
}

export default OnClickButton
