import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Line from "../core/Line"
import InterestForm from "./InterestForm"
import CrowdCube from "../../resources/images/investment/popup/crowdcube.svg"

const StyledContainer = styled.div`
  margin-top: 120px;
`

const Title = styled.h2`
  ${fonts.swissBlackExtended};
  color: ${colors.navy};
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
`

const TitleBlock = styled.div`
  text-align: center;
  p {
    ${fonts.gilroyRegular};
    color: ${colors.darkGrey};
    max-width: 552px;
    margin: 30px auto 10px;
    font-size: 15px;
    line-height: 21px;
  }
  margin-bottom: 51.5px;
`

const Partnership = styled.div`
  ${fonts.gilroyBold};
  font-size: 12px;
  letter-spacing: 2.77px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  img {
    margin-top: 34px;
  }
`

const Interest = () => {
  return (
    <StyledContainer id={"interest-form"}>
      <TitleBlock>
        <Title>Don't miss the boat!</Title>
        <p>
          We think owning a share in a world class beer brand is hugely exciting
          but we want our investors to feel part of something even bigger.
          Please register your details below to find out more about joining the
          crew..
        </p>
        <Line noVertical />
      </TitleBlock>
      {/* <InterestForm /> */}
      <Partnership>
        <div>In partnership with</div>
        <img src={CrowdCube} alt="Crowdcube" />
      </Partnership>
    </StyledContainer>
  )
}

export default Interest
