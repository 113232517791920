/**
 * @type {{xl: string, max: string, md: string, sm: string, lg: string, xs: string, xxl: string}}
 */
const breakpoints = {
  max: '1600px',
  xxl: '1400px',
  xl: '1200px',
  lg: '992px',
  md: '768px',
  sm: '576px',
  xs: '575px'
};

export default breakpoints;
