import React from "react"
import styled from "styled-components"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import PrivateRoute from "../router/PrivateRoute"
import DarkBlock from "../components/investment/DarkBlock"
import WhiteBlock from "../components/investment/WhiteBlock"
import Hero from "../components/investment/Hero"

const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
`

const InvestmentPage = (props) => {
  const path = props.location.pathname
  const {hero, intro, background1, background2, mission, backgroundMobile1, backgroundMobile2, page} = props.data
  const darkBlockProps = { intro, background1, background2, mission, backgroundMobile1, backgroundMobile2};

  return (
    <Layout>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
      />
      <Container>
        <Hero hero={hero} />
        <DarkBlock {...darkBlockProps} />
        <WhiteBlock />
      </Container>
    </Layout>
  )
}

function investment(props) {
  return <PrivateRoute component={InvestmentPage} {...props} />
}
export default investment

export const pageQuery = graphql`
  query InvestmentPageQuery {
    page: contentfulLandingPage(slug: { eq: "investment" }) {
      id
      metaTitle
      metaDescription
    }
    hero: file(relativePath: { eq: "investment/hero-investment.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    intro: file(relativePath: { eq: "investment/investment-intro.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1108) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mission: file(relativePath: { eq: "investment/mission.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1108) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    background1: file(relativePath: { eq: "voyage/background-banner-bottom.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    background2: file(relativePath: { eq: "voyage/introblock-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    backgroundMobile1: file(relativePath: { eq: "voyage/bacground-banner-bottom-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 859) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    backgroundMobile2: file(relativePath: { eq: "voyage/introblock-background-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 859) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

