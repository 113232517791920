import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import WavesImage from "../../resources/images/icons/waves.svg"
import { Row, Col } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import OnClickButton from "../core/OnClickButton"

const ContainerStyled = styled.div`
  color: ${colors.white};
  max-width: 1440px;
  margin: auto;
  position: relative;
  z-index: 1;
`

const RowStyled = styled(Row)`
  margin: 0;
`

const ColStyled = styled(Col)`
  padding: 0 25px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  @media (min-width: ${breakpoints.md}) {
    max-width: 554px;
    margin-left: auto;
  }
`

const Wave = styled.img`
  position: absolute;
  transform: rotate(180deg);
  bottom: -10px;
  left: calc(50% - 41px);
  @media (min-width: ${breakpoints.md}) {
    top: 26%;
    left: -41px;
  }
`

const Content = styled.div`
  padding: 40px 0;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
    padding: 45px 20px 55px 56px;
  }
  @media(min-width: ${breakpoints.xl}) {
    padding: 90px 55px 110px 113px;
  }
`

const IntroText = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.grey};
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 28px;
  @media(min-width: ${breakpoints.md}) {
    max-width: 362px;
    margin-bottom: 56px;
  }
`

const WhiteText = styled.div`
  ${fonts.swissBlackExtended};
  color: ${colors.white};
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin-bottom: 30px;
  max-width: 500px;
  @media(min-width: ${breakpoints.md}) {
    margin-left: -190px;
    margin-bottom: 60px;
  }
`

const ButtonContainer = styled.div`
  max-width: 257px;
  button {
    width: 100%;
    margin-left: 0;
    div {
      width: calc(100% - 5px);
    }
  }

`

const Intro = props => {
  const { image } = props
  
  const scrollTo = (e, sectionId) => {
    if (typeof window !== "undefined") {
      e.preventDefault();
      let yOffset = -50;
      let element = document.getElementById(sectionId);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: "smooth"})
    }
  };

  return (
    <ContainerStyled>
      <RowStyled>
        <ColStyled md={5} mdOffset={1}>
          <ImageContainer>
            <StyledImg
              fluid={image.childImageSharp.fluid}
              alt="Investment Intro Image"
            />
            <Wave src={WavesImage} alt="Blue wave icon" />
          </ImageContainer>
        </ColStyled>
        <ColStyled md={6}>
          <Content>
            <IntroText>
              Lost at Sea is an independent beer brand with a range of
              heritage-inspired, authentic beers characterised by craftsmanship,
              attention to detail and a passion for the British coast. The brand
              was founded by two brothers inspired by their grandfather’s tales
              of life as a seaside evacuee and Britain’s proud seafaring
              heritage.
            </IntroText>
            <WhiteText>
              Curiosity, endeavour and adventure underline everything LAS stands
              for. Which is why the brothers donate 5p from every can sold to
              the RNLI. 
            </WhiteText>
            <ButtonContainer>
              <OnClickButton onClick={(e) => scrollTo(e, "interest-form")}>Register Your Interest</OnClickButton>
            </ButtonContainer>
          </Content>
        </ColStyled>
      </RowStyled>
    </ContainerStyled>
  )
}

export default Intro
