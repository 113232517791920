import React from "react"
import { Zoom } from "react-reveal"
import DecorationTitleImg1 from "../../resources/images/investment/decoration-title-1.svg"
import DecorationTitleImg2 from "../../resources/images/investment/decoration-title-2.svg"
import DecorationTitleImg3 from "../../resources/images/investment/decoration-title-3.svg"
import DecorationTitleImg4 from "../../resources/images/investment/decoration-title-4.svg"
import DecorationTitleImg5 from "../../resources/images/investment/decoration-title-5.svg"
import DecorationTitleImg6 from "../../resources/images/investment/decoration-title-6.svg"
import DecorationTitleImg7 from "../../resources/images/investment/decoration-title-7.svg"
import DecorationTitleImg8 from "../../resources/images/investment/decoration-title-8.svg"
import DecorationTitleImg9 from "../../resources/images/investment/decoration-title-9.svg"
import DecorationTitleImg10 from "../../resources/images/investment/decoration-title-10.svg"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const DecorationBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.md}) {
  }
`

const TitleLetter = styled.img`
  height: 20px;
  margin-right: ${props => props.margin && "8px"};
  @media (min-width: 450px) {
    height: 24px;
  }
  @media (min-width: ${breakpoints.xs}) {
    height: 40px;
    margin-right: ${props => props.margin && "16px"};
  }
  @media (min-width: ${breakpoints.md}) {
    height: 53px;
    margin-right: ${props => props.margin && "20px"};
  }
  @media (min-width: ${breakpoints.xl}) {
    height: 83px;
    margin-right: ${props => props.margin && "80px"};
  }
`

const DecorationTitle = () => {
  return (
    <DecorationBox>
      <Zoom cascade duration={3000}>
        <TitleLetter src={DecorationTitleImg1} />
        <TitleLetter src={DecorationTitleImg2} />
        <TitleLetter src={DecorationTitleImg3} margin />
        <TitleLetter src={DecorationTitleImg4} />
        <TitleLetter src={DecorationTitleImg5} />
        <TitleLetter src={DecorationTitleImg6} />
        <TitleLetter src={DecorationTitleImg7} />
        <TitleLetter src={DecorationTitleImg8} />
        <TitleLetter src={DecorationTitleImg9} />
        <TitleLetter src={DecorationTitleImg10} />
      </Zoom>
    </DecorationBox>
  )
}

export default DecorationTitle
