import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Ambitions from "./Ambitions"
import Interest from "./Interest"

const Background = styled.div`
  background: ${colors.white};
  margin-top: 169px;
`

const ContentContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: auto;
  margin-bottom: 130px;
`

const WhiteBlock = props => {
  return (
    <Background>
      <ContentContainer>
        <Ambitions />
        <Interest />
      </ContentContainer>
    </Background>
  )
}

export default WhiteBlock
