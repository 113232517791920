import React from 'react';
import styled from 'styled-components';


const OverlayStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: transparent;
`;
const BottomOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 126px;

    background: -moz-linear-gradient(to bottom, rgba(2, 9, 20, 0), rgba(2, 9, 20, 1));
    background: -webkit-linear-gradient(to bottom, rgba(2, 9, 20, 0), rgba(2, 9, 20, 1));
    background: linear-gradient(to bottom, rgba(2, 9, 20, 0), rgba(0, 9, 20, 1));
    @media(min-width:768px){
        height: 154px;
    }
`;

const ShortOverlayBottom = () => {
  return (<OverlayStyled>
    <BottomOverlay />
  </OverlayStyled>);
}

export default ShortOverlayBottom;
