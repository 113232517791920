import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Line from "../core/Line"
import { Container, Row, Col } from "styled-bootstrap-grid"
import OnClickButton from "../core/OnClickButton"
import Checked from "../../resources/images/investment/checkmark.svg"
import { first } from "lodash"

const StyledForm = styled.form`
  max-width: 573px;
  margin: auto;
  margin-bottom: 55px;
`

const StyledInput = styled.input`
  ${fonts.gilroyRegular};
  color: ${colors.navy};
  border: 1px solid ${colors.grey};
  font-size: 15px;
  line-height: 21px;
  height: 55px;
  padding: 0 24px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  ::placeholder {
    color: ${colors.darkGrey};
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${colors.darkGrey};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${colors.darkGrey};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${colors.darkGrey};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${colors.darkGrey};
  }
`

const SectionTitle = styled.div`
  ${fonts.gilroyBold};
  color: ${colors.navy};
  font-size: 12px;
  letter-spacing: 2.77px;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 12px;
`

const StyledRow = styled(Row)`
  margin: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  :last-of-type {
    margin-bottom: 0;
  }
`

const StyledCol = styled(Col)`
  padding: 0px;
  display: ${props => (props.flex ? "flex" : "block")};
  margin-bottom: ${props => props.name && "40px"};
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`

const InputContainer = styled.div`
  padding: 0 10px;
`

const Radio = styled.label`
  ${fonts.gilroyMedium};
  color: ${colors.navy};
  font-size: 15px;
  line-height: 21px;
  border: 1px solid ${colors.grey};
  padding: 16px;
  width: 100%;
  margin: 0 10px 20px;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
  }
  .checkmark {
    position: absolute;
    top: calc(50% - 11px);
    height: 22px;
    width: 22px;
    background-color: transparent;
    border: 1px solid ${colors.grey};
    box-sizing: border-box;
  }
  input:checked ~ .checkmark {
    border: 1px solid ${colors.navy};
    background: ${colors.navy};
    background-image: url(${Checked});
    background-repeat: no-repeat;
    background-position: center;
  }
`

const ButtonContainer = styled.div`
  max-width: 252px;
  margin: 50px auto 37px;
  button {
    width: 100%;
    margin-left: 0;
    color: ${colors.navy};
    div {
      width: calc(100% - 5px);
    }
  }
`

const Value = styled.div`
  padding-left: 32px;
`

const TC = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.grey};
  font-size: 13px;
  line-height: 18px;
  text-align: center;
`

const HoneyPot = styled.div`
  display: none;
`

const Success = styled.div`
  margin: 100px auto;
  ${fonts.swissBlackExtended};
  color: ${colors.navy};
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
`

const InterestForm = (props) => {
  const levels = [
    { value: "£10-£500" },
    { value: "£500-£1k" },
    { value: "£1k-£5k" },
    { value: "£5k-£10k" },
    { value: "£10k-£20k" },
    { value: "£20k-£50k" },
    { value: "£50k +" },
  ]

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [interest, setInterest] = useState(levels[0])
  const [success, setSuccess] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    setFormSubmitted(true)

    let investmentForm = document.getElementById("investment-form")
    let formData = new FormData(investmentForm)


    fetch(investmentForm.getAttribute("action"), {
      method: "POST",
      body: formData,
    })
      .then(response => response)
      .then(response => {
        let isSuccess = false

        if (response.status === 200) {
          isSuccess = true

          setSuccess(isSuccess)

          if (typeof window !== "undefined") {
            let dataLayer = window.dataLayer
            if (typeof dataLayer !== "undefined") {
              dataLayer.push({
                event: "formSubmitted",
                formName: "Investment Interest",
              })
            }
          }
        } else {
          setHasErrors(true)
          setFormSubmitted(false)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <StyledForm
      method="POST"
      name="investment"
      data-netlify="true"
      id="investment-form"
      data-netlify-honeypot="bag"
      onSubmit={e => handleSubmit(e)}
    >
      {success ? (
        <Success>Thank you for your interest!</Success>
      ) : (
        <>
          <StyledRow>
            <StyledCol md={6} name>
              <InputContainer>
                <SectionTitle>First Name</SectionTitle>
                <StyledInput
                  type="text"
                  placeholder="Please Enter"
                  id="firstName"
                  name="firstName"
                  aria-label="First Name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </InputContainer>
            </StyledCol>
            <StyledCol md={6}>
              <InputContainer>
                <SectionTitle>Last Name</SectionTitle>
                <StyledInput
                  type="text"
                  placeholder="Please Enter"
                  id="lastName"
                  name="lastName"
                  aria-label="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </InputContainer>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <InputContainer>
                <SectionTitle>Email Address</SectionTitle>
                <StyledInput
                  type="email"
                  placeholder="Please Enter"
                  id="email"
                  name="email"
                  aria-label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </InputContainer>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <InputContainer>
                <SectionTitle>Phone</SectionTitle>
                <StyledInput
                  type="text"
                  placeholder="Please Enter"
                  id="phone"
                  name="phone"
                  aria-label="Phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required
                />
              </InputContainer>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              <InputContainer>
                <SectionTitle>Interest Level</SectionTitle>
              </InputContainer>
            </StyledCol>
            <StyledCol>
              <StyledRow>
                {levels.map((level, index) => {
                  return (
                    <StyledCol xs={12} sm={4} md={4} flex key={index}>
                      <Radio>
                        <input
                          type="radio"
                          name="interest-rate"
                          id={`interest-rate-${index}`}
                          aria-label="Interest Rate"
                          checked={interest.value === level.value}
                          onChange={() => setInterest(level)}
                          value={interest.value}
                          required
                        />
                        <span className="checkmark"></span>
                        <Value>{level.value}</Value>
                      </Radio>
                    </StyledCol>
                  )
                })}
              </StyledRow>
            </StyledCol>
          </StyledRow>
          <HoneyPot>
            <input name="bag" aria-label="investment" />
          </HoneyPot>
          <input
            type="hidden"
            name="form-name"
            value="investment"
            aria-label="investment"
          />
          <ButtonContainer>
            <OnClickButton type="submit">{(success === false && hasErrors) ? "Please try again!" : "Submit your interest"}</OnClickButton>
          </ButtonContainer>
          <TC>
            Investments of this nature carry risks to your capital. Please Invest Aware. By submitting your details you
            agree to our privacy policy. Your pledge amount is purely an indication of interest and does not constitute
            any financial obligation or commitment.
          </TC>
        </>
      )}
    </StyledForm>
  )
}

export default InterestForm
