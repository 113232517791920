import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Line from "../core/Line"
import { Container, Row, Col } from "styled-bootstrap-grid"

const StyledContainer = styled.div`
  padding-bottom: 140px;
  border-bottom: 1px dashed #9CA8B5;
`

const TitleBlock = styled.div`
  text-align: center;
  margin: auto;
  margin-bottom: 50px;
`

const Title = styled.h2`
  ${fonts.swissBlackExtended};
  color: ${props => (props.outline ? "transparent" : colors.navy)};
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: ${colors.navy};
`

const RowStyled = styled(Row)`
  margin: 0;
`

const ColStyled = styled(Col)`
  padding: 0;
`
const Ambition = styled.div`

`

const AmbitionTitle = styled.h3`
  ${fonts.swissBlackExtended};
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  margin: auto;
  margin-bottom: 30px;
  
  @media (min-width: ${breakpoints.md}) {
    max-width: 300px;
    padding-left: 40px;
  }
`

const AmbitionContent = styled.ul`
  ${fonts.gilroyRegular};
  color: ${colors.darkGrey};
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 40px;
  
  li {
    padding-left: 40px;
    padding-bottom: 14px;
    position: relative;
    :before {
      content: "";
      position: absolute;
      width: 60px;
      width: 3px;
      height: 3px;
      top: 10px;
      left: 20px;
      border-radius: 50%;
      background: ${colors.grey};
    }
  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    padding: 0 10px;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 20px;
  }
`

const GridContainer = styled(Container)`
  padding: 0;
`



const Ambitions = () => {
  const lists = [
    {
      title: "Accelerate off and online growth",
      contents: [
        "Continue building our brand value through inspiring content",
        "Further promote and leverage our online presence withcustomer acquisition",
        "Continue investment online improving, user experience and buildingbrand awareness",
      ],
    },
    {
      title: "Aquire a flagship venue",
      contents: [
        "Launch our first flagship seafood bar in Abersoch, north Wales becoming true advocates for local produce and sustainability ",
        "Build a recognisable and scalable bar concept for future expansion in popular costal towns where our customers are ",
        "Purchase a mobile bar to attend events and festivals, which intern will continue to drive brand awareness",
      ],
    },
    {
      title: "Invest in people and product",
      contents: [
        "Assemble some of the best talent in the industry to keep driving product innovation and sales",
        "Continue building strategic alliances and exciting collaborations through brand partnerships",
        "Continue to find the best manufacturing and distribution partners in the industry",
      ],
    },
  ]
  return (
    <StyledContainer>
      <TitleBlock>
        <Title>Our Ambitions</Title>
        <Title outline>For Growth</Title>
        <Line noVertical />
      </TitleBlock>
      <GridContainer>
        <RowStyled>
          {lists.map((list, index) => {
            return (
              <ColStyled key={index} md={4}>
                <Ambition>
                  <AmbitionTitle>{list.title}</AmbitionTitle>
                  <AmbitionContent>
                    {list.contents.map((content, index) => (
                      <li key={index}>{content}</li>
                    ))}
                  </AmbitionContent>
                </Ambition>
              </ColStyled>
            )
          })}
        </RowStyled>
      </GridContainer>
    </StyledContainer>
  )
}

export default Ambitions
