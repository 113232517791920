import React from 'react';
import styled from 'styled-components';
import BlueLineImg from '../../resources/images/brewery/blue-line.svg';
import VerticalLineImg from '../../resources/images/vertical-long-line.svg';

const LinesContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BlueLine = styled.img`
    width: 80px;
    padding-bottom: 28.5px;
    padding-top: 36px;
`;
const VerticalLine = styled.img`
    height: 167px;
    display: ${props => props.visible ? "block" : "none"};
`;

const HeroLines = ({noVertical}) => {
  return (
    <LinesContainer>
      <BlueLine src={BlueLineImg} />
      <VerticalLine src={VerticalLineImg} visible={!noVertical}/>
    </LinesContainer>

  )
}

export default HeroLines;
