import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Intro from "./Intro"
import Invest from "./Invest"
import Mission from "./Mission"
import OverlaySplash from "../happenings/OverlaySplash"
import breakpoints from "../../styles/breakpoints"

const Background = styled.div`
  background: ${colors.darkNavyBackground};
  position: relative;
  padding-bottom: 140px;
  @media( min-width: ${breakpoints.md}) {
    padding-bottom: 185px;
  }
`

const DarkBlock = props => {
  const { intro, background1, background2, mission, backgroundMobile1, backgroundMobile2 } = props
  return (
    <Background>
      <Intro image={intro}  />
      <Invest background={background1} background={background1} backgroundMobile={backgroundMobile1}/>
      <Mission background={background2} image={mission} backgroundMobile={backgroundMobile2}/>
      <OverlaySplash />
    </Background>
  )
}

export default DarkBlock
