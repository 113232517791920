import React from 'react';
import styled from 'styled-components';
import SplashImg from '../../resources/images/happenings/splash-brush-top.png';
import SplashMobileImg from '../../resources/images/happenings/splash-brush-top-mobile.png';

const SplashOverlay = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 141px;
    display: block;
    background-image: url(${SplashMobileImg});
    background-repeat: repeat-x;
    background-size: contain;
    background-position: 0 100%;
    
    @media(min-width: 768px) {
      height: 185px;
      background-image: url(${SplashImg});
    }
`;

const OverlaySplash = () => {
  return (<>
      <SplashOverlay/>
    </>
  )
};

export default OverlaySplash;
