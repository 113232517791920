import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import DecorationTitle from "./DecorationTitle"
import Brand from "../../resources/images/investment/brand.svg"
import Community from "../../resources/images/investment/community.svg"
import Charity from "../../resources/images/investment/charity.svg"
import Sales from "../../resources/images/investment/sales.svg"

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: -300px;
  display: none;
  @media (min-width: 650px) {
    display: block;
  }
`
const BackgroundMobile = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: -430px;
  @media (min-width: 650px) {
    display: none;
  }
`

const ContainerStyled = styled.div`
  height: 100%;
  position: relative;
  padding-bottom: 100px;
  min-height: 620px;
  margin-top: 150px;
`

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
  @media (min-width: ${breakpoints.md}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ColStyled = styled(Col)`
  padding: 0;
`

const WhiteText = styled.div`
  ${fonts.swissBlackExtended};
  color: ${colors.white};
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1.3px;
  max-width: 880px;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  position: relative;
  z-index: 1;
`

const BottomText = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.grey};
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  padding: 26px 0 55px;
  max-width: 220px;
  margin: auto;
`

const IconBox = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 63px;
`

const IconBlcok = styled(Container)`
  display: block;
  margin: 100px auto;
  width: auto;
  padding: 0;
`

const ContentContainer = styled.div`
  padding: 0 25px;
`

const Invest = props => {
  const { background, backgroundMobile } = props
  const investment = [
    {
      icon: Brand,
      content:
        "We’re a new and exciting brand for a new generation of beer lovers",
    },
    {
      icon: Community,
      content: "We have a community of over 30,000 loyal and engaged people",
    },
    {
      icon: Charity,
      content:
        "We're a sustainable and ethically conscious brand that supports charities such as the RNLI",
    },
    {
      icon: Sales,
      content: "Join a growing market that grew 16.4% in 2019 to £178.2M",
    },
  ]
  return (
    <ContainerStyled>
      <BackgroundImage>
        <Img
          fluid={background.childImageSharp.fluid}
          alt="Underwater background"
        />
      </BackgroundImage>
      <BackgroundMobile>
        <Img
          fluid={backgroundMobile.childImageSharp.fluid}
          alt="Underwater background"
        />
      </BackgroundMobile>
      <DecorationTitle />
      <ContentContainer>
        <IconBlcok>
          <StyledRow>
            {investment.map((invest, index) => {
              return (
                <ColStyled xs={12} sm={6} md={6} lg={3} key={index}>
                  <IconBox>
                    <img src={invest.icon} alt={invest.content} />
                  </IconBox>
                  <BottomText>{invest.content}</BottomText>
                </ColStyled>
              )
            })}
          </StyledRow>
        </IconBlcok>
        <WhiteText>
          Building on this foundation the business now wants to grow the brand
          and business exponentially and launch LAS across the UK.
        </WhiteText>
      </ContentContainer>
    </ContainerStyled>
  )
}

export default Invest
